.app {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.message-container {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.message-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    max-width: 70%;
}

.message-wrapper.user {
    align-self: flex-end;
}

.message-wrapper.api {
    align-self: flex-start;
}

.message {
    padding: 10px;
    border-radius: 5px;
    text-align: left;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.message.user {
    background-color: #c6e6f8;
}

.message.api {
    background-color: #f1f0f0;
    max-width: 98%;
    font-family: 'Courier New', Courier, monospace;
    font-size: large;
}

.message.error {
    background-color: #ffcccb;
}

.message-timestamp {
    font-size: 0.8em;
    color: #888;
    margin-bottom: 2px;
    text-align: right;
}

.input-form {
    display: flex;
    padding: 20px;
    background-color: #f0f0f0;
    border-top: 1px solid #ccc;
    box-shadow: 0 -2px 5px rgba(0,0,0,0.05);
}

.input-form input {
    flex-grow: 1;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px 0 0 5px;
}

.submit-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #0105f4;
    color: white;
    border: none;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #0104aa;
}

.message.api.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    max-width: 50px;
}

.csv-table-container {
    padding-left: 10px;
    padding-right: 10px;
    overflow-x: scroll;
}

.csv-table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.csv-table th, .csv-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.csv-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.csv-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.csv-table tr:hover {
    background-color: #f5f5f5;
}
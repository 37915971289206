.add-files {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    gap: 80px;
    /* background-color: #f5f5f5; */
    border-radius: 5px;
  }
  
  .add-files h2 {
    margin-bottom: 20px;
    font-size: 24px;
  }

  .add-files .instruction-note-container {
    margin-bottom: 20px;
  }

  .add-files .note {
    margin-top: 5px;
    font-size: 16px;
    color: #666;
  }
  
  .add-files .button-group .add-files-button {
    padding: 10px 15px;
    background-color: #0066cc;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
  }
  
  .add-files .button-group .add-files-button:hover {
    background-color: #0052a3;
  }
  
  .add-files .button-group .add-files-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .add-files .button-group .cancel-add-files-button {
    padding: 10px 15px;
    background-color: #f0f0f0;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
  }
  
  .add-files .button-group .cancel-add-files-button:hover {
    background-color: #e0e0e0;
  }

  .add-files .select-files .select-files-button {
    padding: 10px 15px;
    background-color: #0066cc;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
  }
  
  .add-files .select-files .select-files-button:hover {
    background-color: #0052a3;
  }
  
  .add-files .select-files .select-files-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  .add-files .file-list {
    list-style-type: none;
    padding: 0;
    margin: 20px 0;
  }
  
  .add-files .file-list li {
    background-color: #e0e0e0;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 4px;
  }
  
  .add-files .error-message {
    color: #ff0000;
    margin-top: 10px;
  }
  
  .add-files .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .add-files .button-group button {
    width: 48%;
  }
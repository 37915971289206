.integrations-container {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 96%;
  max-width: 1200px;
}

.integrations-container h3 {
  margin-bottom: 20px;
}

.table-container {
  width: 100%;
  position: relative;
  margin-top: 20px;
}

.integrations-loading,
.integrations-error {
  text-align: center;
  padding: 20px;
  font-size: 18px;
}

.integrations-error {
  color: #ff0000;
}

.integrations-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.integrations-table th,
.integrations-table td {
  /* border: 1px solid #ddd;
  padding: 12px; */
  text-align: left;
}

.integrations-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.integrations-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.integrations-table tr:hover {
  background-color: #f5f5f5;
}

.center-align {
  text-align: center;
}

.credentials-column {
  max-width: 50px;
  overflow: auto;
  text-overflow: ellipsis;
  white-space: normal;
  text-align: center;
}

.board-name-column {
  width: 33%;
}

.webhooks-table-actions {
  width: 140px;
}

.check-icon {
  color: #4caf50;
  display: inline-flex;
  align-items: center; /* Center vertically */
  justify-content: center;
  width: 100%;
}

.x-icon {
  color: #ff4136;
  display: inline-flex;
  align-items: center; /* Center vertically */
  justify-content: center;
  width: 100%;
}

.add-integration-button {
  position: absolute;
  top: -45px;
  right: 0;
  background-color: #0066cc;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.add-integration-button:hover {
  background-color: #0052a3;
}

.add-integration-button svg {
  margin-right: 5px;
}

.edit-button,
.delete-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0 5px;
}

.edit-button {
  color: #4caf50;
}

.delete-button {
  color: #ff4136;
}

.edit-button:hover,
.delete-button:hover {
  opacity: 0.7;
}

.integration-form {
  padding: 20px;
}

.integration-select {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.integration-details {
  margin-top: 20px;
}

.integration-details p {
  margin-bottom: 10px;
}

.webhook-toggle {
  margin: 20px 0;
}

.webhook-toggle label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.webhook-toggle input[type="checkbox"] {
  margin-right: 10px;
}

.add-button,
.authenticate-button {
  background-color: #0066cc;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.add-button:hover,
.authenticate-button:hover {
  background-color: #0052a3;
}

.description {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.scopes {
  margin-bottom: 20px;
}

.scopes h4 {
  margin-bottom: 10px;
}

.scopes ul {
  list-style-type: none;
  padding-left: 0;
}

.scopes li {
  margin-bottom: 5px;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border-radius: 3px;
}

.board-webhooks-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  margin-bottom: 20px;
}

.board-webhooks-table th,
.board-webhooks-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.board-webhooks-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.add-webhooks-button,
.delete-webhooks-button {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.add-webhooks-button {
  background-color: #0066cc;
  color: white;
}

.delete-webhooks-button {
  background-color: #ff4136;
  color: white;
}

.add-webhooks-button:hover {
  background-color: #0052a3;
}

.delete-webhooks-button:hover {
  background-color: #d63129;
}

.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  z-index: 1001;
}

.toast.success {
  background-color: #4caf50;
}

.toast.error {
  background-color: #ff4136;
}

.toast.warning {
  background-color: #ff9800;
}

.add-webhooks-button,
.delete-webhooks-button {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  min-width: 140px;
  justify-content: center;
}

.add-webhooks-button:disabled,
.delete-webhooks-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.spin-icon {
  animation: spin 1s linear infinite;
}

.redoak-form {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.form-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px !important;
  font-family: inherit;
}

input[type="password"] {
  font-size: 14px !important;
  font-family: inherit;
  margin-bottom: 10px;
}

.secret-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.secret-input-container .form-input {
  padding-right: 40px;
}

.toggle-secret-button {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  padding: 0;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.toggle-secret-button svg {
  width: 16px;
  height: 16px;
}

.toggle-secret-button:hover {
  color: #333;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.delete-integration-confirmation-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.delete-integration-confirmation-dialog p {
  margin-bottom: 20px;
  text-align: center;
}

.delete-integration-button-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.delete-integration-button-group button {
  width: 48%;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.delete-integration-button-group button:first-of-type {
  background-color: #ff4136;
  color: white;
}

.delete-integration-button-group button:first-of-type:hover {
  background-color: #e63933;
}

.delete-integration-button-group button:last-of-type {
  background-color: #f0f0f0;
  color: black;
}

.delete-integration-button-group button:last-of-type:hover {
  background-color: #e4e4e4;
}

@media (max-width: 768px) {
  .integrations-container {
    padding: 10px;
  }

  .add-integration-button {
    position: static;
    margin-bottom: 20px;
  }

  .table-container {
    overflow-x: auto;
  }

  .integrations-table {
    font-size: 14px;
  }

  .integration-form {
    padding: 10px;
  }
}

.task-card {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    font-family: Arial, sans-serif;
}
  
.client-name {
    font-size: 13px;
    color: #666;
    margin-bottom: 4px;
    margin-top: 0;
}
  
.task-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
    margin-top: 2px;
    line-height: 1.4;
    display: -webkit-box;
    display: flex;
    line-clamp: 5;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: calc(1.4em * 5);
}
  
.card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
  
.card-meta {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
}
  
.priority-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.attachment-info, .due-date {
    display: flex;
    align-items: center;
    gap: 4px;
}

.assignee-wrapper {
    position: relative;
}
  
.assignee-initials {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    color: white;
    cursor: pointer;
}

.assignee-tooltip {
    visibility: hidden;
    width: auto;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    transform: translateX(-75%);
    opacity: 0;
    transition: opacity 0.3s;
    white-space: nowrap;
}

.assignee-wrapper:hover .assignee-tooltip {
    visibility: visible;
    opacity: 1;
}

/* Priority-specific background colors */
.bg-red-100 { background-color: #fee2e2; }
.bg-orange-100 { background-color: #ffedd5; }
.bg-yellow-100 { background-color: #ffffff; }
.bg-green-100 { background-color: #ffffff; }

/* Priority-specific text colors */
.text-red-500 { color: #ef4444; }
.text-orange-500 { color: #f97316; }
.text-yellow-500 { color: #22c55e; }
.text-green-500 { color: #2281c5; }

/* Due date styles */
.due-date {
    padding: 2px 4px;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
}

.due-date-warning {
    background-color: #fef08a;
    color: #854d0e;
}

.due-date-overdue {
    background-color: #ef4444;
    color: white;
}
.bar-chart-container {
  height: 100%;
  width: 100%;
}

.bar-chart-legend {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
}

.bar-chart-legend li {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.legend-color {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border-radius: 50%;
}

.legend-label {
  color: #666;
}

.custom-tooltip::before,
.custom-tooltip::after {
  content: none !important;
}

/* Responsive styles */
@media (max-width: 768px) {
  .legend-label {
    font-size: 12px;
  }
}

.donut-chart-container {
    height: calc(100% - 15px);
    width: 100%;
  }
  
  .donut-chart-legend {
    list-style: none;
    padding: 0;
    margin: 10px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
  }
  
  .donut-chart-legend li {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  
  .legend-color {
    display: inline-block;
    width: 6px;
    height: 6px;
    margin-right: 8px;
    border-radius: 50%;
  }
  
  .legend-label {
    color: #666;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .donut-chart-container {
      padding: 5px;
    }
  
    .legend-label {
      font-size: 12px;
    }
  }
.client-edit {
  padding: 20px;
  width: 96%;
  max-width: 1200px;
  min-width: 800px;
}

.client-edit h2,
.client-edit h3 {
  margin-bottom: 20px;
}

.client-edit input[type="text"] {
  width: calc(100% - 23px);
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
}

.loading-container,
.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 18px;
  color: #666;
}

.error-container {
  color: #ff4136;
}

.client-edit-content {
  margin-top: 20px;
  position: relative;
  z-index: 1;
}

.edit-button,
.delete-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0 5px;
}

.edit-button {
  color: #4caf50;
}

.delete-button {
  color: #ff4136;
}

.edit-button:hover,
.delete-button:hover {
  opacity: 0.7;
}

.name-save {
  padding: 10px 15px;
  margin-right: 10px;
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.name-save:hover {
  background-color: #0052a3;
}

.client-name-container,
.assigned-container {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 96%;
  max-width: 1200px;
}

.add-assigned-employee {
  position: absolute;
  top: -45px;
  right: 0;
  background-color: #0066cc;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-right: 0px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.add-assigned-employee:hover {
  background-color: #0052a3;
}

.add-assigned-employee svg {
  margin-right: 5px;
}

.client-edit table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.client-edit th,
.client-edit td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.client-edit th {
  background-color: #f2f2f2;
}

.client-edit ul {
  list-style-type: none;
  padding: 0;
}

.client-edit li {
  margin-bottom: 5px;
}

.edit-assigned-employee-dialog,
.delete-assigned-employee-confirmation-dialog,
.add-assigned-employee-dialog {
  display: flex;
  flex-direction: column;
  align-items: space-between;
}

.edit-assigned-employee-button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.edit-assigned-employee-button-group button {
  width: 48%;
}

.edit-assigned-employee-dialog h4,
.add-assigned-employee-dialog h4 {
  margin-top: 0;
  margin-bottom: 15px;
}

.edit-assigned-employee-dialog select,
.add-assigned-employee-dialog select {
  display: block;
  width: calc(100% - 20px); /* Subtract 20px to account for the padding */
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.edit-assigned-employee-dialog button,
.add-assigned-employee-dialog button,
.delete-assigned-employee-confirmation-dialog button {
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
}

.edit-assigned-employee-dialog button:first-of-type,
.add-assigned-employee-dialog button:first-of-type {
  background-color: #0066cc;
  color: white;
}

.edit-assigned-employee-dialog button:first-of-type:hover,
.add-assigned-employee-dialog button:first-of-type:hover {
  background-color: #0052a3;
}

.delete-assigned-employee-confirmation-dialog button:first-of-type {
  background-color: #ff4136;
  color: white;
}

.delete-assigned-employee-confirmation-dialog button:first-of-type:hover {
  background-color: #e63933;
}

.edit-assigned-employee-dialog button:last-of-type,
.add-assigned-employee-dialog button:last-of-type,
.delete-assigned-employee-confirmation-dialog button:last-of-type {
  background-color: #f0f0f0;
  color: black;
}

.edit-assigned-employee-dialog button:last-of-type:hover,
.add-assigned-employee-dialog button:last-of-type:hover,
.delete-assigned-employee-confirmation-dialog button:last-of-type:hover {
  background-color: #e4e4e4;
}

.harvest-config-container {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 96%;
  max-width: 1200px;
  border-bottom: 1px solid #eee;
}

.harvest-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.harvest-client-select,
.harvest-projects-select {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.harvest-client-select select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  max-width: 400px;
}

.project-checkboxes {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.project-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
}

.project-checkbox input[type="checkbox"] {
  margin: 0;
}

.project-checkbox label {
  font-size: 14px;
  cursor: pointer;
}

.harvest-save-button {
  padding: 10px 15px;
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: flex-start;
}

.harvest-save-button:hover {
  background-color: #0052a3;
}

.current-harvest-config {
  margin-top: 20px;
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.current-harvest-config h4 {
  margin-top: 0;
  margin-bottom: 15px;
}

.current-harvest-config ul {
  list-style-type: none;
  padding-left: 0;
  margin-top: 5px;
}

.current-harvest-config li {
  padding: 5px 0;
}

.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  z-index: 1001;
}

.toast.success {
  background-color: #4caf50;
}

.toast.error {
  background-color: #ff4136;
}

@media (max-width: 1050px) {
  .client-edit {
    min-width: auto;
  }
}

@media (max-width: 768px) {
  .client-edit {
    padding: 10px;
  }

  .client-edit table {
    font-size: 14px;
  }

  .delete-assigned-employee-confirmation-dialog {
    width: calc(100% - 20px);
    padding: 10px;
  }

  .edit-assigned-employee-dialog select,
  .add-assigned-employee-dialog select {
    width: 100%;
  }

  .harvest-config-container {
    padding: 10px;
  }

  .harvest-client-select select {
    max-width: 100%;
  }
}

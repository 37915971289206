.completed-reviews {
    padding: 20px;
    width: 96%;
    max-width: 1200px;
  }
  
  .completed-reviews h2,
  .completed-reviews h3 {
    margin-bottom: 20px;
  }
  
  .loading-container,
  .error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    font-size: 18px;
    color: #666;
  }
  
  .error-container {
    color: #ff4136;
  }
  
  .completed-reviews-content {
    margin-top: 20px;
  }
  
  .search-bar {
    margin-bottom: 20px;
    width: calc(100% - 20px);
  }
  
  .search-bar input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .completed-reviews table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    table-layout: fixed;
  }
  
  .completed-reviews th,
  .completed-reviews td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .completed-reviews th {
    background-color: #f2f2f2;
    cursor: pointer;
  }
  
  .completed-reviews tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .completed-reviews tr:hover {
    background-color: #f5f5f5;
  }
  
  .completed-reviews th:nth-child(1),
  .completed-reviews td:nth-child(1) {
    width: 40%;
  }
  
  .completed-reviews th:nth-child(2),
  .completed-reviews td:nth-child(2),
  .completed-reviews th:nth-child(3),
  .completed-reviews td:nth-child(3),
  .completed-reviews th:nth-child(4),
  .completed-reviews td:nth-child(4) {
    width: 15%;
  }
  
  .completed-reviews th:nth-child(5),
  .completed-reviews td:nth-child(5) {
    width: 15%;
  }
  
  .sort-indicator {
    margin-left: 5px;
  }
  
  .sort-indicator.asc::after {
    content: '▲';
  }
  
  .sort-indicator.desc::after {
    content: '▼';
  }
  
  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination button {
    background-color: #0066cc;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .pagination button:hover {
    background-color: #0052a3;
  }
  
  .pagination button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .no-results {
    text-align: center;
    padding: 20px;
    font-size: 18px;
    color: #666;
  }
  
  @media (max-width: 768px) {
    .completed-reviews {
      padding: 10px;
    }
  
    .completed-reviews table {
      font-size: 14px;
    }
  
    .completed-reviews th,
    .completed-reviews td {
      padding: 6px;
    }
  }
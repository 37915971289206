.task-details {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.task-details button {
  appearance: none;
  border: none;
  background: none;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.task-details.popup-mode {
  max-width: none;
  margin: 0;
  padding: 0;
}

.task-details .client-name-container {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.task-details .client-name {
  font-size: 18px;
  margin-bottom: 5px;
  color: #336;
  transition: color 0.3s ease;
  display: inline-block;
}

.task-details .client-name a {
  color: #0066cc;
  text-decoration: none;
}

.task-details .client-name a:hover {
  color: #004499;
}

.task-details .client-name.clickable {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.task-details .client-name.clickable:hover {
  color: #004499;
}

.task-details .client-name.clickable svg {
  margin-left: 5px;
  font-size: 14px;
}

.task-details .client-dropdown {
  position: relative;
  display: inline-block;
}

.task-details .client-list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  color: #333;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 400px;
  overflow-y: auto;
  min-width: 100%;
  white-space: nowrap;
}

.task-details .client-list li {
  padding: 8px 15px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.task-details .client-list li:hover {
  background-color: #f0f0f0;
}

.task-details h1 {
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 5px;
}

.task-details .created-date {
  font-size: 14px;
  color: #666;
  margin-top: 0;
  margin-bottom: 10px;
}

.task-details .assigned-to {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.task-details .assigned-to strong {
  margin-right: 10px;
  min-width: 50px;
  flex-shrink: 0;
}

.task-details .assigned-to select {
  margin-left: 10px;
}

.task-details .section-heading {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 0;
  color: #333;
}

.task-details .task-info p {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.task-details .task-info strong {
  margin-right: 10px;
  min-width: 50px;
  flex-shrink: 0;
}

.task-details .task-info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.task-details .task-info-item {
  display: flex;
  flex-direction: column;
  position: relative;
}

.task-details .task-info-item label {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 5px;
}

.task-details .task-info-item select,
.task-details .task-info-item input[type="text"],
.task-details .task-info-item input[type="number"],
.task-details .react-datepicker__input-container input {
  width: 100%;
  padding: 8px 10px;
  font-size: 12pt;
  color: #333;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
}

.task-details .task-info-item select:hover,
.task-details .task-info-item input[type="text"]:hover,
.task-details .task-info-item input[type="number"]:hover,
.task-details .react-datepicker__input-container input:hover {
  background-color: #e0e0e0;
}

.task-details .task-info-item.full-width {
  grid-column: span 2;
}

.task-details .task-info-item.align-right {
  justify-self: end;
  width: 100%;
}

.task-details .due-completed-container {
  display: contents;
}

.task-details .due-completed-container > div {
  grid-column: span 1;
}

.task-details .due-date-container {
  display: flex;
  align-items: center;
  width: auto;
}

.task-details .date-picker-wrapper {
  flex-grow: 1;
}

.task-details .react-datepicker-wrapper,
.task-details .react-datepicker__input-container {
  display: block;
  width: 100%;
}

.task-details .react-datepicker__input-container input {
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  padding: 8px 10px;
  font-size: 12pt;
  color: #333;
  width: 100%;
}

.task-details .react-datepicker__input-container input:hover {
  background-color: #e0e0e0;
}

.task-details .due-date-reason-toggle {
  background: none;
  border: none;
  color: #0066cc;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  display: flex;
  align-items: center;
  margin-left: 10px;
  flex-shrink: 0;
}

.task-details .due-date-reason-overlay {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 200%;
  margin-top: 5px;
}

.task-details .due-date-reason {
  font-size: 14px;
  color: #333;
  margin: 0;
}

.task-details .task-info-item input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.task-details .task-info-item input[type="number"]::-webkit-inner-spin-button,
.task-details .task-info-item input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.task-details .react-datepicker-popper {
  z-index: 2;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.task-details h3 {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.task-details .task-attachments,
.task-details .task-emails {
  margin-top: 20px;
}

.task-details .task-attachments ul,
.task-details .task-emails ul {
  list-style-type: none;
  padding-left: 0;
}

.task-details .task-attachments li,
.task-details .task-emails li {
  margin-bottom: 10px;
}

.task-details .task-attachments a {
  color: #0066cc;
  text-decoration: none;
  word-break: break-all;
}

.task-details .task-attachments a:hover {
  text-decoration: underline;
}

.task-details .task-emails-notes {
  margin-top: 20px;
}

.task-details .task-emails-notes ul {
  list-style-type: none;
  padding-left: 0;
}

.task-details .email-note-item {
  margin-bottom: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
}

.task-details .note-item {
  background-color: #f9f9f9;
}

.task-details .note-header {
  font-size: 12px;
  color: #666;
  padding: 5px 10px;
  background-color: #f0f0f0;
}

.note-email-icon {
  font-size: 12px;
  color: #666;
  margin-right: 8px;
}

.task-details .email-note-content {
  cursor: pointer;
  padding: 10px;
  padding-right: 30px;
  transition: background-color 0.2s ease;
  position: relative;
}

.task-details .email-note-content:hover {
  background-color: #f5f5f5;
}

.task-details .email-note-chevron {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 12px;
  color: #666;
  transition: transform 0.2s ease;
}

.task-details .email-note-content.expanded .email-note-chevron {
  transform: rotate(180deg);
}

.task-details .email-sender {
  color: #888;
}

.task-details-loading,
.task-details-error,
.task-details-not-found {
  text-align: center;
  margin-top: 50px;
  font-size: 18px;
}

.task-details-error {
  color: #ff0000;
}

.popup-mode.task-details {
  max-width: none;
  margin: 0;
  padding: 0;
}

.popup-mode .due-date-reason-toggle,
.popup-mode .due-date-reason-toggle:hover {
  background: none !important;
  border: none !important;
  color: #0066cc !important;
}

.popup-mode .due-date-reason-toggle:hover {
  color: #004499 !important;
}

.react-datepicker-wrapper {
  display: inline-block;
}

.task-details .react-datepicker-wrapper {
  width: auto;
}

.task-details .react-datepicker__input-container {
  width: auto;
}

.react-datepicker__input-container input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.task-details select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  padding: 8px 30px 8px 10px;
  font-size: 12pt;
  color: #333;
  cursor: pointer;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: right 8px top 50%;
  background-size: 16px;
}

.task-details select:hover {
  background-color: #e0e0e0;
}

.task-details select:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 102, 204, 0.2);
}

.task-details .task-info-item.full-width {
  grid-column: span 2;
}

.task-details .task-info-item.align-right {
  justify-self: end;
}

.task-details .task-info-item input[type="number"],
.task-details .task-info-item input[type="text"] {
  max-width: 95%;
}

.note-visibility-selector {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.note-visibility-selector label {
  font-weight: 500;
  font-size: 0.9rem;
}

.note-visibility-selector select {
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Style for client-visible note textarea */
.add-note-popup textarea[style*="background-color: rgb(255, 255, 208)"] {
  border-color: #e6e6b8;
}

@media (max-width: 600px) {
  .task-details h1 {
    font-size: 20px;
  }

  .task-details h3 {
    font-size: 16px;
  }
}

.task-details .delete-task-container {
  margin-top: 30px;
  text-align: right;
}

.task-details .delete-task-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #ff4136;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
}

.task-details .delete-task-button:hover {
  color: #d50000;
  background-color: transparent;
}

.task-details .delete-task-button svg {
  margin-right: 5px;
}

.task-details .confirmation-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.task-details .confirmation-dialog p {
  margin-bottom: 15px;
}

.task-details .confirmation-dialog button {
  margin: 0 10px;
  padding: 5px 15px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.task-details .confirmation-dialog button:first-of-type {
  background-color: #ff4136 !important;
  color: white;
}

.task-details .confirmation-dialog button:first-of-type:hover {
  background-color: #d50000 !important;
}

.task-details .confirmation-dialog button:last-of-type {
  background-color: #f0f0f0 !important;
}

.task-details .confirmation-dialog button:last-of-type:hover {
  background-color: #e0e0e0 !important;
}

.task-details .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 15px;
}

.task-details .section-header::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px;
  height: 1px;
  background-color: #ddd;
}

.task-details .add-note-button {
  background: transparent;
  border: none;
  color: #0066cc;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 10px 10px 0 10px;
  transition: color 0.2s ease;
  position: relative;
  bottom: -5px;
}

.task-details .add-note-button:hover {
  color: #004499;
  background-color: transparent;
}

.task-details .add-note-button svg {
  margin-right: 5px;
}

.task-details .add-note-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.task-details .add-note-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 500px;
}

.task-details .add-note-content textarea {
  width: calc(100% - 20px);
  height: 100px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

.task-details .add-note-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.task-details .add-note-actions button {
  width: 48%;
}

.task-details .post-note-button,
.task-details .cancel-note-button {
  padding: 8px 15px;
  border-radius: 4px;
  font-size: 14px;
  margin-left: 10px;
  transition: background-color 0.2s ease;
}

.task-details .post-note-button {
  background-color: #0066cc;
  color: white;
  border: none;
}

.task-details .post-note-button:hover {
  background-color: #0052a3;
}

.task-details .cancel-note-button {
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid #ccc;
}

.task-details .cancel-note-button:hover {
  background-color: #e0e0e0;
}

.task-details .add-file-button {
  background: transparent;
  border: none;
  color: #0066cc;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 10px 10px 0 10px;
  transition: color 0.2s ease;
  position: relative;
  bottom: -5px;
}

.task-details .add-file-button:hover {
  color: #004499;
  background-color: transparent;
}

.task-details .add-file-button svg {
  margin-right: 5px;
}

@media (max-width: 768px) {
  .task-details .task-info-grid {
    grid-template-columns: 1fr;
  }

  .task-details .due-completed-container > div {
    grid-column: span 1;
  }

  .task-details .due-date-reason-overlay {
    width: 100%;
  }

  .task-details .task-info-item.full-width,
  .task-details .task-info-item.align-right {
    grid-column: span 1;
    justify-self: start;
  }

  .task-details .task-info-item input[type="number"],
  .task-details .task-info-item input[type="text"] {
    max-width: 96.5%;
  }

  .task-details .delete-task-container {
    text-align: center;
    margin-top: 20px;
  }

  .task-details .delete-task-button {
    width: 100%;
    justify-content: center;
    padding: 10px;
    font-size: 14px;
  }

  .task-details .confirmation-dialog {
    width: 90%;
    max-width: 300px;
    padding: 15px;
  }

  .task-details .confirmation-dialog p {
    font-size: 14px;
  }

  .task-details .confirmation-dialog button {
    padding: 8px 12px;
    font-size: 14px;
  }
}

.dashboard-table-container {
    height: calc(100% - 30px);
    width: 100%;
    overflow: hidden;
  }
  
  .table-wrapper {
    height: 100%;
    width: 100%;
    overflow: auto;
  }
  
  .dashboard-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    table-layout: fixed;
    font-size: 14px;
  }
  
  .dashboard-table th,
  .dashboard-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .dashboard-table th {
    background-color: #f2f2f2;
    cursor: pointer;
    position: relative;
    padding-right: 20px;
  }
  
  .dashboard-table th:hover {
    background-color: #e6e6e6;
  }
  
  .dashboard-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .dashboard-table tr:hover {
    background-color: #f5f5f5;
  }
  
  /* Tooltip styles */
  .tooltip-container {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .custom-tooltip {
    position: fixed;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 13px;
    max-width: 300px;
    word-wrap: break-word;
    transform: translateY(8px);
    animation: tooltipFade 0.2s ease-in-out;
  }
  
  .custom-tooltip::after {
    content: '';
    position: absolute;
    top: -6px;
    left: 10px;
    border-width: 0 6px 6px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
  }
  
  @keyframes tooltipFade {
    from {
      opacity: 0;
      transform: translateY(16px);
    }
    to {
      opacity: 1;
      transform: translateY(8px);
    }
  }
  
  /* Cell content styles */
  .cell-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  
  .header-content {
    padding-right: 12px;
  }
  
  /* Sort indicators */
  .sort-indicator {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .sort-indicator.asc::after {
    content: '▲';
    font-size: 10px;
  }
  
  .sort-indicator.desc::after {
    content: '▼';
    font-size: 10px;
  }
  
  /* Column width distribution */
  .dashboard-table th:first-child,
  .dashboard-table td:first-child {
    width: 25%;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .dashboard-table {
      font-size: 12px;
    }
  
    .dashboard-table th,
    .dashboard-table td {
      padding: 6px;
    }
  }
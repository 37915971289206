.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 800px; /* Fixed maximum width */
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-sizing: border-box;
}

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none !important;
  border: none !important;
  font-size: 20px;
  cursor: pointer;
  color: #333 !important;
  padding: 5px;
  line-height: 1;
}

.popup-close:hover {
  color: #666 !important;
}

/* Style specifically for the question mark button */
.popup-content .due-date-reason-toggle {
  background: none !important;
  border: none !important;
  color: #0066cc !important;
  font-size: 16px;
  padding: 0;
  vertical-align: middle;
}

.popup-content .due-date-reason-toggle:hover {
  color: #004499 !important;
}

/* Override any inherited styles for links within the popup */
.popup-content a {
  color: #0066cc;
  text-decoration: none;
}

.popup-content a:hover {
  text-decoration: underline;
}

/* Additional specificity for TaskDetails within popup */
/* .popup-content .task-details button,
  .popup-content .task-details button:hover {
    background: none !important;
    border: none !important;
  }
  
  .popup-content .task-details .due-date-reason-toggle {
    color: #0066cc !important;
  }
  
  .popup-content .task-details .due-date-reason-toggle:hover {
    color: #004499 !important;
  } */

/* Media query for smaller screens */
@media (max-width: 600px) {
  .popup-content {
    width: 95%;
    padding: 15px;
  }
}

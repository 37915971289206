.employees-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 96%;
    max-width: 1200px;
  }

  .employees-container h2 {
    align-self: start;
  }
  
  .table-container {
    width: 100%;
    position: relative;
    margin-top: 20px;
  }

  .employees-loading,
  .employees-error {
    text-align: center;
    padding: 20px;
    font-size: 18px;
  }
  
  .employees-error {
    color: #ff0000;
  }
  
  .employees-table {
    width: 100%;
    max-width: 1200px;
    min-width: 300px;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .employees-table th,
  .employees-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  .employees-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .employees-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .employees-table tr:hover {
    background-color: #f5f5f5;
  }
  
  .employees-table th.sortable {
    cursor: pointer;
    user-select: none;
  }
  
  .employees-table th.sortable:hover {
    background-color: #e0e0e0;
  }
  
  .employees-table th svg {
    margin-left: 5px;
    vertical-align: middle;
  }

  .center-align {
    text-align: center;
  }
  
  .check-icon {
    color: #4CAF50;
  }
  
  .name-column {
    width: 35%;
  }
  
  .email-column {
    width: 40%;
  }
  
  .assignee-column {
    width: 15%;
    max-width: 100px;
  }
  
  .action-column {
    width: 10%;
    max-width: 80px;
  }
  
  .delete-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #ff4136;
    font-size: 16px;
  }
  
  .delete-button:hover {
    color: #d50000;
  }
  
  .confirmation-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .confirmation-dialog p {
    margin-bottom: 15px;
  }
  
  .confirmation-dialog button {
    margin: 0 10px;
    padding: 5px 15px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .confirmation-dialog button:first-of-type {
    background-color: #ff4136;
    color: white;
  }
  
  .confirmation-dialog button:last-of-type {
    background-color: #f0f0f0;
  }

  .add-employee-button {
    position: absolute;
    top: -35px;
    right: 0;
    background-color: #0066cc;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .add-employee-button:hover {
    background-color: #0052a3;
  }
  
  .add-employee-button svg {
    margin-right: 5px;
  }
  
  .add-employee-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 300px;
  }
  
  .add-employee-dialog h3 {
    margin-top: 0;
  }
  
  .add-employee-dialog input {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 3px;
  }
  
  .add-employee-dialog button {
    margin: 0 10px 0 0;
    padding: 5px 15px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .add-employee-dialog button:first-of-type {
    background-color: #4CAF50;
    color: white;
  }
  
  .add-employee-dialog button:last-of-type {
    background-color: #f0f0f0;
  }
  
  .toast {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    z-index: 1001;
  }
  
  .toast.success {
    background-color: #4CAF50;
  }
  
  .toast.error {
    background-color: #ff4136;
  }
  
  @media (max-width: 768px) {
    .add-employee-button {
        position: static;
        margin-bottom: 20px;
        align-self: flex-end;
      }
      
      .table-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
  }
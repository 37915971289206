.file-table-container {
  margin: 20px 0;
  width: 100%;
  overflow-x: auto;
}

.file-table {
  width: 120%;
  border-collapse: collapse;
  margin-bottom: 20px;
  table-layout: fixed;
}

.file-table th,
.file-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-table th {
  background-color: #f2f2f2;
  cursor: pointer;
  position: relative;
}

.file-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.file-table tr:hover {
  background-color: #f5f5f5;
}

.file-table td.icon-cell {
  text-align: center;
}

.file-table td.created-cell {
  white-space: normal;
  line-height: 1.2;
  font-size: 0.9em;
}

.file-table td.pages-cell {
  text-align: right;
  padding-right: 16px;
}

.file-table td.created-by-cell {
  white-space: nowrap;
}

.file-link {
  color: #0066cc;
  text-decoration: none;
  word-wrap: break-word;
  white-space: normal;
}

.file-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.pages-cell {
  text-align: right;
}

.sort-indicator {
  margin-left: 5px;
  font-size: 12px;
}

.sort-indicator.asc::after {
  content: "▲";
}

.sort-indicator.desc::after {
  content: "▼";
}

.type-filter {
  position: relative;
}

.type-filter-button {
  background: none !important;
  border: none !important;
  cursor: pointer;
  padding: 0 !important;
  font: inherit !important;
  color: inherit !important;
}

.type-filter-dropdown {
  position: fixed;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  min-width: 150px;
  z-index: 10000;
}

.type-filter-option {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
}

.type-filter-option:hover {
  background-color: #f5f5f5;
}

.type-filter-option input {
  margin-right: 8px;
}

.hidden-files-message {
  margin-top: -10px;
  font-size: 14px;
  color: #666;
}

@media (max-width: 768px) {
  .file-table {
    font-size: 14px;
  }

  .file-table th,
  .file-table td {
    padding: 6px;
  }
}

.state-mapping-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 0;
}

.state-mapping-section {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  background-color: #fff;
}

.state-mapping-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.state-mapping-label {
  font-weight: 500;
  color: #333;
  font-size: 14px;
}

.add-mapping-button {
  background-color: #0066cc;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  transition: background-color 0.2s;
}

.add-mapping-button:hover {
  background-color: #0052a3;
}

.button-icon {
  font-size: 12px;
}

.mapping-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mapping-item {
  display: flex;
  gap: 10px;
  align-items: center;
}

.mapping-input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  font-family: inherit;
}

.mapping-input:focus {
  outline: none;
  border-color: #0066cc;
  box-shadow: 0 0 0 2px rgba(0, 102, 204, 0.1);
}

.remove-mapping-button {
  background: none;
  border: none;
  color: #ff4136;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s;
}

.remove-mapping-button:hover {
  color: #e63933;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .state-mapping-section {
    padding: 10px;
  }

  .state-mapping-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .add-mapping-button {
    width: 100%;
    justify-content: center;
  }

  .mapping-item {
    flex-direction: column;
  }

  .remove-mapping-button {
    align-self: flex-end;
  }
}

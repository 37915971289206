h1 {
  font-size: 1em;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.App-logo {
  height: 3vmin;
  padding-left: 5px;
  padding-right: 10px;
}

.SignedOut-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 50px);
  width: 100%;
}

.SignedOut-logo {
  max-width: 500px;
  max-height: 500px;
  width: auto;
  height: auto;
}

.SignedOut-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.SignedOut-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #0078d4;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.SignedOut-login-button:hover {
  background-color: #106ebe;
}

.Microsoft-logo {
  width: 30px;
  height: 30px;
  margin-right: 10px; /* Space between logo and text */
}

.SignedOut-login-button span {
  flex: 1;
}

.App-header {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 15px;
  background-color: #2f2f38;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.App-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

.header-spacer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  margin-left: auto;
  width: 250px;
  color: white;
  padding: 0 10px;
}

.toggle-container {
  display: flex;
  align-items: center;
}

.toggle-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: white;
}

.toggle-label span {
  margin: 0 5px;
}

.toggle-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  background-color: #ccc;
  border-radius: 34px;
  transition: background-color 0.2s;
}

.toggle-switch:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.2s;
}

.toggle-input:checked + .toggle-switch {
  background-color: #2196f3;
}

.toggle-input:checked + .toggle-switch:before {
  transform: translateX(26px);
}

.content-wrapper {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.side-menu {
  width: 200px;
  height: 100%;
  background-color: #e0e0e0;
  transition: 0.3s;
  overflow-y: auto;
  flex-shrink: 0;
}

.side-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.side-menu li {
  padding: 0;
}

.side-menu a {
  display: block;
  padding: 15px 20px;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s;
}

.side-menu a:hover {
  background-color: #d0d0d0;
  color: #000;
}

.side-menu a:active {
  background-color: #c0c0c0;
}

.environment-toggle {
  padding: 15px 20px;
  border-top: 1px solid #ccc;
  margin-top: 20px;
}

.environment-toggle span {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  font-weight: bold;
  color: #444;
}

.environment-toggle .toggle-label {
  margin-left: 10px;
}

.toggle-container {
  display: flex;
  align-items: center;
}

.toggle-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toggle-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  background-color: #ccc;
  border-radius: 12px;
  transition: background-color 0.2s;
  margin-right: 10px;
}

.toggle-switch:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.2s;
}

.toggle-input:checked + .toggle-switch {
  background-color: #2196f3;
}

.toggle-input:checked + .toggle-switch:before {
  transform: translateX(16px);
}

.toggle-label span {
  color: #444;
}

.main-content {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-right: 15px;
  color: white;
}

.user-dropdown {
  position: relative;
}

.user-dropdown-toggle {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
}

.user-dropdown-toggle svg {
  margin-right: 8px;
}

.user-dropdown-toggle span {
  margin-right: 8px;
}

.user-dropdown-menu {
  position: absolute;
  right: 0;
  top: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
  width: 250px; /* Fixed width */
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;
}

.user-dropdown-open .user-dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.profile-info {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.logout-button {
  display: block;
  width: 100%;
  padding: 8px 16px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  color: #333;
}

.logout-button:hover {
  background-color: #f5f5f5;
}

.save-button {
  padding: 10px 15px;
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-self: flex-start;
}

.save-button:hover {
  background-color: #0052a3;
}

.header-spacer {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 0 10px;
}

@media (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
  }

  .side-menu {
    width: 100%;
    height: auto;
    display: none;
  }

  .side-menu.open {
    display: block;
  }

  .main-content {
    width: 100%;
    flex: 1;
  }

  .menu-toggle {
    display: block;
  }
}

.dashboard-container {
    padding: 20px;
    width: 100%;
    overflow: auto;
  }
  
  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-right: 40px;
  }
  
  .dashboard-header h2 {
    margin: 0;
  }

  .dashboard-error {
    color: red;
    font-size: 18px;
    text-align: center;
    margin-top: 50px;
  }
  
  .dashboard-loading {
    color: #666;
    font-size: 18px;
    text-align: center;
    margin-top: 50px;
  }

  .edit-toggle {
    padding: 8px 16px;
    background-color: #0066cc;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .edit-toggle:hover {
    background-color: #0052a3;
  }
  
  .dashboard-grid {
    --column-width: 100px;
    --grid-gap: 16px;
    display: grid;
    min-width: min-content;
    min-height: 100vh;
    margin: 0 auto;
    position: relative;
    grid-auto-rows: var(--column-width);
    grid-auto-flow: dense; /* Helps fill in gaps */
    align-items: start;
    justify-items: stretch;
  }
  
  .dashboard-tile {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    transition: transform 0.2s ease;
    width: 100%;
    height: 100%;
    min-height: var(--column-width);
    margin: 0;
    box-sizing: border-box;
  }
  
  .dashboard-tile.dragging {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .dashboard-tile-ghost {
    position: relative;
    border-radius: 8px;
    pointer-events: none;
    transition: all 0.2s ease;
    display: block;
    box-sizing: border-box;
    min-height: 100px;
    animation: pulse 1.5s ease infinite;
  }

  @keyframes pulse {
    0% { opacity: 0.3; }
    50% { opacity: 0.4; }
    100% { opacity: 0.3; }
  }
  
  .dashboard-tile-ghost {
    animation: pulse 1.5s ease infinite;
  }
  
  .dashboard-tile-header {
    padding: 12px 16px;
    border-bottom: 1px solid #eee;
    font-weight: 700;
    color: #333;
    display: flex;
    justify-content: center;
  }
  
  .dashboard-tile-content {
    padding: 16px;
    height: calc(100% - 45px); /* Subtract header height */
    overflow: auto;
  }

  .resize-handle {
    position: absolute;
    background-color: #0066cc;
    border-radius: 2px;
    transition: background-color 0.2s;
  }
  
  .resize-handle:hover {
    background-color: #0052a3;
  }
  
  .resize-handle-e {
    cursor: e-resize;
    width: 4px;
    height: 20px;
    right: -2px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .resize-handle-s {
    cursor: s-resize;
    height: 4px;
    width: 20px;
    bottom: -2px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .resize-handle-se {
    cursor: se-resize;
    width: 10px;
    height: 10px;
    right: -5px;
    bottom: -5px;
  }
  
  .dashboard-tile.resizing {
    opacity: 0.8;
    z-index: 1000;
  }
  
  /* Show resize handles only when editing */
  .resize-handle {
    display: none;
  }
  
  .dashboard-tile:hover .resize-handle {
    display: block;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1400px) {
    .dashboard-grid {
        width: max-content;
        --column-width: 80px;
        --grid-gap: 16px;
      }
  }
  
  @media (max-width: 768px) {
    .dashboard-grid {
      --column-width: 60px;
      --grid-gap: 16px;
    }
    
    .dashboard-tile-header {
      padding: 8px 12px;
    }
    
    .dashboard-tile-content {
      padding: 12px;
    }
  
    .resize-handle-se {
      width: 8px;
      height: 8px;
    }
  }
  
  @media (max-width: 576px) {
    .dashboard-container {
      padding: 10px;
    }
    
    .dashboard-header {
      flex-direction: column;
      gap: 10px;
    }
    
    .edit-toggle {
      width: 100%;
    }
  }
.task-board {
  padding: 20px;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

h2 {
  margin-bottom: 20px;
}

.filter-container {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.filter-input {
  flex: 1;
  max-width: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.employee-filter,
.client-filter {
  position: relative;
}

.filter-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-color: #f0f0f0;
  color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  min-width: 200px;
}

.filter-button:hover {
  background-color: #e0e0e0;
}

.filter-button-text {
  flex-grow: 1;
  text-align: left;
}

.filter-button-icon {
  margin-left: 8px; /* Adds some space between the text and the icon */
}

.employee-list,
.client-list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  color: #333;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 400px;
  overflow-y: auto;
  min-width: 100%;
  white-space: nowrap;
}

.employee-item,
.client-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.employee-item:hover,
.client-item:hover {
  background-color: #e0e0e0;
}

.employee-item span,
.client-item span {
  margin-left: 5px;
}

.type-filter {
  position: relative;
}

.type-list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  color: #333;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 400px;
  overflow-y: auto;
  min-width: 100%;
  white-space: nowrap;
}

.type-item {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  margin-bottom: 5px;
}

.type-item:hover {
  background-color: #e0e0e0;
}

.type-item input[type="checkbox"] {
  margin-right: 8px;
}

.type-item span {
  margin-left: 5px;
}

.add-task-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 15px;
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.columns-container {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  flex: 1;
}

.column {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
}

.column h2 {
  padding: 10px;
  margin: 0;
  font-size: 18px;
  position: sticky;
  top: 0;
  background: inherit;
  z-index: 1;
}

.column-content {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  padding-top: 0;
}

.column.open {
  background-color: #e8f5e9;
}
.column.assigned {
  background-color: #e3f2fd;
}
.column.in-progress {
  background-color: #f3e5f5;
}
.column.pending-client {
  background-color: #fff3e0;
}
.column.completed {
  background-color: #fff9c4;
}
.column.cancelled {
  background-color: #f5f5f5;
}
.column.internal-review {
  background-color: #e0f2f1;
}

.column.open h2 {
  color: #2e7d32;
}
.column.assigned h2 {
  color: #1565c0;
}
.column.in-progress h2 {
  color: #7b1fa2;
}
.column.pending-client h2 {
  color: #ef6c00;
}
.column.completed h2 {
  color: #f9a825;
}
.column.cancelled h2 {
  color: #616161;
}
.column.internal-review h2 {
  color: #00796b;
}

.error-message {
  color: red;
  font-size: 18px;
  text-align: center;
  margin-top: 50px;
}

/* Ensure draggable items don't shrink */
[data-rbd-draggable-context-id] {
  flex-shrink: 0;
}

/* Add some space between task cards */
[data-rbd-draggable-context-id] > div {
  margin-bottom: 10px;
}

.client-reviews {
  padding: 20px;
  width: 96%;
  max-width: 1200px;
}

.client-reviews h2,
.client-reviews h3 {
  margin-bottom: 20px;
}

.loading-container,
.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 18px;
  color: #666;
}

.error-container {
  color: #ff4136;
}

.client-reviews-content {
  margin-top: 20px;
  position: relative;
  z-index: 1;
}

.client-tabs {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #ddd;
}

.client-tabs a {
  padding: 10px 15px;
  text-decoration: none;
  color: #333;
  border: 1px solid transparent;
  border-bottom: none;
  margin-bottom: -1px;
}

.client-tabs a.active {
  background-color: #fff;
  border-color: #ddd;
  border-bottom-color: #fff;
}

.client-tabs a:hover {
  background-color: #f5f5f5;
}

/* Table styling */
.client-reviews table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.client-reviews th,
.client-reviews td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.client-reviews th {
  background-color: #f2f2f2;
}

.client-reviews tr:nth-child(even) {
  background-color: #f9f9f9;
}

.client-reviews tr:hover {
  background-color: #f5f5f5;
}

/* Button styling */
.client-reviews button {
  padding: 10px 15px;
  margin-right: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

.client-reviews button:hover {
  background-color: #45a049;
}

/* Toast styling */
.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  z-index: 1001;
}

.toast.success {
  background-color: #4CAF50;
}

.toast.error {
  background-color: #ff4136;
}

/* Responsive design */
@media (max-width: 768px) {
  .client-reviews {
    padding: 10px;
  }

  .client-reviews table {
    font-size: 14px;
  }

  .client-reviews th,
  .client-reviews td {
    padding: 6px;
  }

  .client-tabs {
    flex-wrap: wrap;
  }

  .client-tabs a {
    flex-grow: 1;
    text-align: center;
  }
}
.client-tabs {
  display: flex;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.tab {
  padding: 10px 20px;
  text-decoration: none;
  color: #333;
  border: 1px solid transparent;
  border-bottom: none;
  margin-bottom: -1px;
  min-width: 100px;
}

.tab.active {
  background-color: #fff;
  border-color: #ccc;
  border-bottom-color: #fff;
  font-weight: bold;
}

.tab:hover {
  background-color: #f5f5f5;
}

.add-task {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .add-task h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .add-task .error-message {
    color: #ff0000;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #fff5f5;
    border-radius: 4px;
  }
  
  .add-task form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .add-task .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .add-task .form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .title-note-reason {
    margin-right: 20px;
  }
  
  .add-task label {
    margin-bottom: 5px;
    color: #666;
    font-size: 0.9em;
  }
  
  .add-task input,
  .add-task select,
  .add-task textarea,
  .add-task .date-picker{
    padding: 8px 10px;
    font-size: 12pt;
    color: #333;
    background-color: #f0f0f0;
    border: none;
    border-radius: 4px;
  }
  
  .add-task input,
  .add-task select,
  .add-task textarea{
    width: 100%;
  }

  .add-task .date-picker {
    width: 94.5%;
  }

  .add-task select {
    appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position: right 8px top 50%;
    background-size: 16px;
    padding-right: 30px;
  }
  
  .add-task textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .add-task input:hover,
  .add-task select:hover,
  .add-task textarea:hover {
    background-color: #e0e0e0;
  }
  
  .add-task .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
  }
  
  .add-task .form-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
  }
  
  .add-task .form-actions button[type="submit"] {
    background-color: #0066cc;
    color: white;
  }
  
  .add-task .form-actions button[type="submit"]:hover {
    background-color: #0052a3;
  }
  
  .add-task .form-actions button[type="submit"]:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .add-task .form-actions button[type="button"] {
    background-color: #f0f0f0;
    color: #333;
  }
  
  .add-task .form-actions button[type="button"]:hover {
    background-color: #e0e0e0;
  }
  
  @media (max-width: 600px) {
    .add-task .form-row {
      grid-template-columns: 1fr;
    }
  }